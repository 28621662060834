import { SavedItem } from 'model';
import dateformat from 'dateformat';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSignedInUser, putSavedItem, SavedItemsPutParams } from 'model/feedSlice';
import { useAppDispatch } from 'app/hooks';
import ManageTagsDialog from './ManageTagsDialog';

type Props = {
  savedItem: SavedItem;
};

export default function SavedItemListItem(props: Props) {
  const { savedItem } = props;
  const [tagsArray, setTagsArray] = useState<string[]>(savedItem.tags ? savedItem.tags.split(',') : []);
  const [showManageTags, setShowManageTags] = useState(false);
  const user = useSelector(getSignedInUser);
  const dispatch = useAppDispatch();

  const handleArchive = () => {
    if (user) {
      dispatch(putSavedItem({ userId: user.email, url: savedItem.url, status: 'archive', tags: savedItem.tags ?? '' } as SavedItemsPutParams));
    }
  };

  const handleMarkNew = () => {
    if (user) {
      dispatch(putSavedItem({ userId: user.email, url: savedItem.url, status: 'new', tags: savedItem.tags ?? '' } as SavedItemsPutParams));
    }
  };

  useEffect(() => {
    setTagsArray(savedItem.tags ? savedItem.tags.split(',') : []);
  }, [savedItem]);

  return (
    <li className="w-full">
      <div className="p-6 w-full border-solid">
        <div className="w-full flex justify-between">
          <div className={'sm:mb-0 flex flex justify-between w-full sm:w-auto gap-6 ' + (savedItem.status === 'archive' ? 'opacity-40' : 'opacity-100')}>
            {savedItem.imageSrc && (
              <div className="hidden sm:flex h-24 w-36 overflow-hidden rounded-xl flex-none">
                <LazyLoadImage className="w-full h-full object-cover overflow-hidden" src={savedItem.imageSrc} />
              </div>
            )}
            <div>
              <a className="hover:text-frblue main-text" href={savedItem.url} target="_blank" rel="noreferrer">
                {savedItem.title}
              </a>
              <div className="mt-3 hidden sm:block">
                <div className="detail-text flex gap-6 items-center">
                  {savedItem.feedTitle && <div className="font-bold max-w-xs">{savedItem.feedTitle}</div>}
                  <div className="self-start">{dateformat(savedItem.created, 'mm/dd/yyyy h:MM TT')}</div>
                </div>
              </div>
              {tagsArray && tagsArray.length > 0 && (
                <div className="gap-3 flex-wrap mt-3 hidden sm:flex">
                  {tagsArray?.map((tag: string, index) => (
                    <div key={`${tag}_${index}`} className="flex items-center gap-3 px-3 py-1.5 rounded-full bg-gray-700 text-gray-200 text-xs">
                      <div>{tag}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {savedItem.imageSrc && (
              <div className="sm:hidden h-16 w-20 overflow-hidden rounded-xl flex-none">
                <LazyLoadImage className="w-full h-full object-cover overflow-hidden" src={savedItem.imageSrc} />
              </div>
            )}
          </div>
          <Menu as="div" className="hidden sm:flex relative inline-block text-left">
            <div>
              <Menu.Button className="flex items-center rounded-full bg-gray-800 hover:bg-gray-700 text-gray-400 p-1 focus:outline-none">
                <span className="sr-only">Open options</span>
                <DotsVerticalIcon className="h-7 w-7" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-gray-200 focus:outline-none">
                {savedItem.status === 'new' && (
                  <Menu.Item>
                    <button className="block hover:bg-gray-300 cursor-pointer rounded-lg w-full" onClick={handleArchive}>
                      <div className="text-left p-3 font-medium text-gray-900">Archive</div>
                    </button>
                  </Menu.Item>
                )}
                {savedItem.status === 'archive' && (
                  <Menu.Item>
                    <button className="block hover:bg-gray-300 cursor-pointer rounded-lg w-full" onClick={handleMarkNew}>
                      <div className="text-left p-3 font-medium text-gray-900">Mark New</div>
                    </button>
                  </Menu.Item>
                )}
                <Menu.Item>
                  <button className="block hover:bg-gray-300 cursor-pointer rounded-lg w-full" onClick={() => setShowManageTags(true)}>
                    <div className="text-left p-3 font-medium text-gray-900">Manage Tags</div>
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="mt-3 flex sm:hidden justify-between items-end w-full">
          <div className={'sm:flex justify-start gap-3 detail-text ' + (savedItem.status === 'archive' ? 'opacity-40' : 'opacity-100')}>
            {savedItem.feedTitle && <span className="font-bold">{savedItem.feedTitle}</span>}
            <div className="mt-3">{dateformat(savedItem.created, 'mm/dd/yyyy h:MM TT')}</div>
            {tagsArray && tagsArray.length > 0 && (
              <div className="flex gap-3 flex-wrap mt-3">
                {tagsArray?.map((tag: string, index) => (
                  <div key={`${tag}_${index}`} className="flex items-center gap-3 px-3 py-1.5 rounded-full bg-gray-700 text-gray-200 text-xs">
                    <div>{tag}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex items-center rounded-full bg-gray-800 hover:bg-gray-700 text-gray-400 p-1 focus:outline-none">
                <span className="sr-only">Open options</span>
                <DotsVerticalIcon className="h-7 w-7" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-gray-200 focus:outline-none">
                {savedItem.status === 'new' && (
                  <Menu.Item>
                    <button className="block hover:bg-gray-300 cursor-pointer rounded-lg w-full" onClick={handleArchive}>
                      <div className="text-left p-3 font-medium text-gray-900">Archive</div>
                    </button>
                  </Menu.Item>
                )}
                {savedItem.status === 'archive' && (
                  <Menu.Item>
                    <button className="block hover:bg-gray-300 cursor-pointer rounded-lg w-full" onClick={handleMarkNew}>
                      <div className="text-left p-3 font-medium text-gray-900">Mark New</div>
                    </button>
                  </Menu.Item>
                )}
                <Menu.Item>
                  <button className="block hover:bg-gray-300 cursor-pointer rounded-lg w-full" onClick={() => setShowManageTags(true)}>
                    <div className="text-left p-3 font-medium text-gray-900">Manage Tags</div>
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <ManageTagsDialog open={showManageTags} setOpen={setShowManageTags} savedItem={props.savedItem} />
    </li>
  );
}
