import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { LightningBoltIcon } from '@heroicons/react/solid';
import AddFeedDialog from './AddFeedDialog';
import { useHistory } from 'react-router-dom';

export default function WelcomeOverlay() {
  const [open, setOpen] = useState(true);
  const [showAddFeed, setShowAddFeed] = useState(false);
  const history = useHistory();

  const handleSettingsClick = () => {
    setOpen(false);
    history.push('/settings');
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={() => setOpen(false)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-gray-900 rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6">
              <div className="mb-6">
                <div className="w-full mb-3">
                  <LightningBoltIcon className="bg-fryellow rounded-full h-16 w-16 m-auto p-3 text-gray-800" />
                </div>
                <div className="text-center text-xl font-bold text-frpink">Welcome to the party!</div>
              </div>
              <div className="p-6 bg-gray-800 rounded-xl mb-12">
                <div className="text-lg font-bold text-fryellow">First things first. You'll need some feeds!</div>
                <div className="text-gray-400 mb-12">Don't sweat it, you have 2 options for adding feeds:</div>
                <div className="mb-12 flex">
                  <div className="text-gray-100 font-medium mr-3">1.</div>
                  <div>
                    <div className="text-gray-100 font-medium">Add a feed by the RSS URL:</div>
                    <div className="text-gray-400 text-sm">It's going to look something like this, https://gizmodo.com/rss (the exact format will vary by site).</div>
                    <div className="w-full pt-6">
                      <button onClick={() => setShowAddFeed(true)} className="pushable bg-frblue-700 rounded-xl w-full">
                        <span className="front bg-frblue button-text rounded-xl px-6 py-3 w-full">Add A New RSS Feed URL</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="text-gray-100 font-medium mr-3">2.</div>
                  <div>
                    <div className="text-gray-100 font-medium">Import a bunch of feeds from an OPML file:</div>
                    <div className="text-gray-400 text-sm">Most RSS readers will give you the option to export your feeds as an OPML file.</div>
                    <div className="text-gray-400 text-sm">Once you have the OPML file you can import it in Settings.</div>
                    <div className="w-full mt-6">
                      <button onClick={() => handleSettingsClick()} className="pushable bg-frblue-700 rounded-xl w-full ">
                        <span className="front bg-frblue button-text rounded-xl px-6 py-3 w-full ">Open Settings</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <button onClick={() => setOpen(false)} className="pushable bg-frpink-700 rounded-xl w-full ">
                  <span className="front bg-frpink button-text rounded-xl px-6 py-3 w-full ">Dismiss</span>
                </button>
              </div>
              <AddFeedDialog open={showAddFeed} setOpen={setShowAddFeed} />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
