import { ViewType } from 'model';
import { getAllFeedItems } from 'model/feedSlice';
import { useSelector } from 'react-redux';
import EmptyState from './EmptyState';
import FeedItemListItem from './FeedItemListItem';
import Header from './Header';
import MainContent from './MainContent';
import MarkAllReadButton from './MarkAllReadButton';

export default function AllItems() {
  const allItems = useSelector(getAllFeedItems);
  console.log(allItems.length);
  return (
    <div className="sm:p-6">
      <Header title="All Items" viewType={ViewType.ALL} showToggle={true} />
      <MainContent
        content={
          <div>
            <ul className="list overflow-hidden">
              {allItems.map((feedItem, index) => (
                <FeedItemListItem key={index} feedItem={feedItem} />
              ))}
            </ul>
            {allItems.length > 0 ? <MarkAllReadButton feedId="all" /> : <EmptyState />}
          </div>
        }
      />
    </div>
  );
}
