import { ViewType } from 'model';
import ActionButtons from './ActionButtons';

type Props = {
  viewType: ViewType;
  title: string;
  showToggle?: boolean;
};

export default function Header({ viewType, title, showToggle = false }: Props) {
  return (
    <header className="hidden sm:block">
      <div className="max-w-5xl mx-auto px-6 ">
        <div className="sm:flex sm:justify-between">
          <h1 className="header-text self-center">{title}</h1>
          <div className="mt-6 sm:mt-0 flex gap-6">
            <ActionButtons viewType={viewType} showToggle={showToggle} />
          </div>
        </div>
      </div>
    </header>
  );
}
