import { FeedItem, ViewType } from 'model';
import { getFeeds, sortFeedItemsByDate } from 'model/feedSlice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ActionButtons from './ActionButtons';
import EmptyState from './EmptyState';
import FeedItemListItem from './FeedItemListItem';
import MarkAllReadButton from './MarkAllReadButton';

type Props = {};

export default function FeedDetail(props: Props) {
  let history = useHistory();
  const feedState = useSelector(getFeeds);

  // @ts-ignore
  let { feedId } = useParams();

  const feed = feedState.feeds.find((feed) => {
    return feed.id === feedId;
  });

  useEffect(() => {
    if (!feed) {
      history.push('/feeds');
    }
  }, [feedState, history, feed]);

  let items: FeedItem[] = [];
  if (feed) {
    for (const feedItem of feed.items) {
      items.push(feedItem);
    }
    sortFeedItemsByDate(items);
  }

  return (
    <div className="sm:p-6">
      <header className="hidden sm:block">
        <div className="max-w-5xl mx-auto px-6">
          <div className="sm:flex sm:justify-between items-center gap-3">
            <h1 className="header-text">{feed?.title}</h1>
            <div className="flex gap-6">
              <ActionButtons viewType={ViewType.DETAIL} />
            </div>
          </div>
        </div>
      </header>
      <main className="max-w-5xl mx-auto pt-6 sm:pt-0 sm:mt-6 bg-gray-900 sm:rounded-xl">
        <ul className="list">
          {items?.map((feedItem, index) => (
            <FeedItemListItem key={index} feedItem={feedItem} />
          ))}
        </ul>
        {items?.length > 0 ? <MarkAllReadButton feedId={feedId} /> : <EmptyState />}
      </main>
    </div>
  );
}
