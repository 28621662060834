import { PlusIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import AddTagDialog from './AddTagDialog';
import { useSelector } from 'react-redux';
import { getSignedInUser, putUser } from 'model/feedSlice';
import { useAppDispatch } from 'app/hooks';

type Props = {};

export default function ManageTags(props: Props) {
  const user = useSelector(getSignedInUser);

  const [showAddTag, setShowAddTag] = useState(false);
  const [tagsArray, setTagsArray] = useState<string[]>(user?.tags ? user.tags.split(',') : []);
  const dispatch = useAppDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const handleRemoveTag = (tag: string) => {
    setTagsArray(tagsArray.filter((str) => str !== tag));
  };

  const handleAddTag = (tag: string) => {
    setTagsArray([...tagsArray, tag]);
    setShowAddTag(false);
  };

  useEffect(() => {
    if (user && !isFirstLoad) {
      let u = Object.assign({}, user);
      let updatedTagsString = tagsArray.join();

      let shouldUpdate = false;
      if (u.tags !== updatedTagsString) {
        shouldUpdate = true;
        u.tags = tagsArray.join();
      }
      console.log(u.tags);
      if (shouldUpdate) {
        shouldUpdate = false;
        dispatch(putUser(u));
      }
    } else {
      setIsFirstLoad(false);
    }
  }, [tagsArray, dispatch, user]);

  return (
    <Fragment>
      <div className="block">
        <div className="flex items-center gap-6 mb-3">
          <label className="label-text self-center">Saved Item Tags</label>
          <button className="icon-button-container" onClick={() => setShowAddTag(true)}>
            <PlusIcon className="icon" />
          </button>
        </div>
        <div className="mb-6 text-base text-gray-400">
          <span className="text-fryellow font-medium">You're not an animal!</span>&nbsp;Organize your saved items!
        </div>
        <div className="bg-gray-800 p-6 rounded-xl">
          {tagsArray && tagsArray.length > 0 ? (
            <div className="flex gap-3 flex-wrap">
              {tagsArray?.map((tag: string, index) => (
                <div key={`${tag}_${index}`} className="flex items-center gap-3 px-3 py-1.5 rounded-full bg-gray-700 text-gray-100">
                  <div>{tag}</div>
                  <button className="rounded-full h-5 w-5 bg-gray-800 hover:bg-gray-600 p-1" onClick={() => handleRemoveTag(tag)}>
                    <XIcon />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="main-text">No Saved Item Tags</div>
          )}
        </div>
      </div>
      <AddTagDialog open={showAddTag} setOpen={setShowAddTag} handleAddTag={handleAddTag} />
    </Fragment>
  );
}
