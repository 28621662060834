import { BookmarkIcon, RefreshIcon } from '@heroicons/react/outline';
import { useAppDispatch } from 'app/hooks';
import { FeedRequestParams, getFeeds, getSignedInUser, refreshFeeds } from 'model/feedSlice';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AddFeedDialog from './AddFeedDialog';
import Lottie from 'lottie-react';
import astronaut from 'assets/astronaut-2.json';

export default function EmptyState() {
  const [showAddFeed, setShowAddFeed] = useState(false);
  const feedState = useSelector(getFeeds);
  const user = useSelector(getSignedInUser);
  const dispatch = useAppDispatch();

  return (
    <div className="px-6 pt-6 pb-12">
      <div className="w-full">
        <BookmarkIcon className="bg-gray-700 text-gray-200 rounded-full h-16 w-16 m-auto p-3" />
      </div>
      {feedState.feeds.length === 0 ? (
        <div>
          <div className="text-center text-xl font-bold text-fryellow py-6">Subscribe to some feeds to get reading!</div>
          <div className="w-full pt-6 text-center">
            <button onClick={() => setShowAddFeed(true)} className="pushable bg-frblue-700 rounded-xl w-full sm:w-auto">
              <span className="front bg-frblue button-text rounded-xl px-6 py-3 w-full sm:w-auto">Add A New RSS Feed URL</span>
            </button>
          </div>
          <div className="text-center text-frblue text-sm pt-3">
            <Link to="/settings">Or Import An OPML File In Settings</Link>
          </div>
          <AddFeedDialog open={showAddFeed} setOpen={setShowAddFeed} />
        </div>
      ) : (
        <div>
          <div className="text-center text-xl font-bold text-fryellow py-6">Nothing new to read...</div>
          <div className="w-full pt-6 text-center">
            <button onClick={() => dispatch(refreshFeeds({ userId: user?.email } as FeedRequestParams))} className="pushable bg-frblue-700 rounded-xl w-full sm:w-auto">
              <div className="front-icon bg-frblue button-text rounded-xl px-6 py-3 w-full sm:w-auto flex-row justify-center">
                <RefreshIcon className="h-6 w-6 mr-3" />
                <span>Refresh Feeds</span>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
