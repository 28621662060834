import logo192 from 'assets/logo192.png';

export default function PrivacyPolicy() {
  return (
    <div>
      <div className="bg-gradient-to-tl to-slate-900 via-slate-800 from-frpurple-700">
        <div className="relative overflow-hidden">
          <div className="relative py-12">
            <div className="mx-auto max-w-3xl">
              <div>
                <div className="flex mb-6">
                  <div className="px-6 mx-auto">
                    <div>
                      <div>
                        <a href="/">
                          <img className="rounded-xl overflow-hidden w-20 mx-auto mb-6" src={logo192} alt="letsreadsomestuuff.com logo" />
                          <h1 className="text-4xl tracking-tight font-extrabold text-fryellow text-center sm:leading-none lg:text-5xl xl:text-6xl cartridge-bold">Lukemisto</h1>
                        </a>
                      </div>
                      <div className="mt-3 sm:mt-5 ">
                        <p className=" text-xl text-center mb-6">
                          <span className="font-bold text-frpink">Privacy Policy</span>
                        </p>
                        <p className="text-gray-300 p-6">
                          <p className="mb-6 font-bold text-xl">Thank you for choosing Lukemisto. </p>
                          <p className="mb-6">
                            Your privacy is very important to us and we are committed to protecting it. This privacy policy explains what data we collect when you use the app and how we use it.
                          </p>
                          <p className="mb-3">
                            <p className="font-bold text-xl">Information we collect:</p>
                          </p>
                          <p className="mb-3">
                            <p className="font-bold"> Information you provide:</p>
                            When you login to Lukemisto through “Sign In With Apple”, Lukemisto is provided with a unique ID that identifies youraccount. This ID consists of a string of numbers and
                            letters and cannot be traced back to you personally. We do not collect your name or email address.
                          </p>
                          <p className="mb-3">
                            <p className="font-bold">Usage Information:</p> We may also collect information about how you use the app, such as what features you use, the pages you visit, and the
                            actions you take. This information is anonymous and cannot be traced back to you.
                          </p>
                          <p className="mb-6">
                            <p className="font-bold">Crash reports:</p>We may also collect information regarding crashes that happen while using the app. This information is anonymous and cannot be
                            traced back to you.
                          </p>
                          <p className="font-bold text-xl mb-3">We may use the above information we collect for the following purposes:</p>
                          <p className="font-bold">To provide our app services:</p> Your unique ID is used to identify your account within Lukemisto and match your account with your data (feeds and
                          saved items).
                          <p className="font-bold mt-3">To maintain our app:</p>
                          Usage information and crash reports are used to operate and improve Lukemisto.
                          <p className="mt-6 text-fryellow">This Privacy Policy may change at any time.</p>
                          <p className="text-fryellow">
                            If you have questions about this privacy policy please email <a href="mailto:support@mkesuperdigital.com">support@mkesuperdigital.com</a>
                          </p>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-16 sm:mt-24 md:mt-0 md:col-span-6 sm:px-6 w-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
