import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useAppDispatch } from 'app/hooks';
import { SavedItem } from 'model';
import { SavedItemsPutParams, SavedItemsRequestParams, getSignedInUser, putSavedItem, requestSavedItems } from 'model/feedSlice';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  open: boolean;
  setOpen: Function;
  status: string;
};

export default function FilterSavedItemsDialog(props: Props) {
  const user = useSelector(getSignedInUser);
  const fullTagsArray = user?.tags ? user.tags.split(',') : [];
  const [tagsArray, setTagsArray] = useState<string[]>(fullTagsArray);
  const dispatch = useAppDispatch();

  const handleSelectTag = (tag: string | undefined) => {
    dispatch(requestSavedItems({ userId: user?.email, filter: tag, status: props.status } as SavedItemsRequestParams));
    props.setOpen(false);
  };

  return (
    <Fragment>
      {/*start add feed modal*/}
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => props.setOpen(false)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-gray-900 rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm w-full sm:p-6">
                <div className="block">
                  <div className="mb-6">
                    <span className="header-text">Filter By Tag</span>
                  </div>
                  <div className="bg-gray-800 p-6 rounded-xl">
                    {tagsArray && tagsArray.length > 0 ? (
                      <div className="flex gap-3 flex-wrap">
                        {tagsArray?.map((tag: string, index) => (
                          <div key={`${tag}_${index}`} className="flex items-center gap-3 px-3 py-1.5 rounded-full bg-gray-700 text-gray-100 cursor-pointer" onClick={() => handleSelectTag(tag)}>
                            <div>{tag}</div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="main-text">No Saved Item Tags</div>
                    )}
                  </div>
                  <div className="mt-6">
                    <button className="text-base font-bold text-frblue hover:underline hover:text-frblue-600" onClick={() => handleSelectTag(undefined)}>
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}
