import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import logo192 from 'assets/logo192.png';
import iosShareIcon from 'assets/ios-share-icon.png';

export default function AddToHomeScreen() {
  let hasShownAddToHomeScreen = localStorage.getItem('hasShownAddToHomeScreen');
  const [show, setShow] = useState(hasShownAddToHomeScreen !== 'true');

  const handleDismiss = () => {
    localStorage.setItem('hasShownAddToHomeScreen', 'true');
    setShow(false);
  };

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-gray-700 shadow-lg rounded-xl pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4 text-center">
                <div className="fixed">
                  <button
                    className="bg-frorange rounded-full inline-flex text-gray-50 hover:bg-frblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 p-1.5"
                    onClick={() => {
                      handleDismiss();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <img className="rounded-xl overflow-hidden h-12 w-12 m-auto mb-6" src={logo192} alt="Lukemisto logo" />

                <div className="mb-6">
                  <div className="text-lg text-fryellow font-bold flex-shrink">Add lukemisto.com to your homescreen!</div>
                </div>
                <div className="rounded-xl p-4 bg-gray-800">
                  {/iPhone|iPad|iPod/i.test(navigator.userAgent) ? (
                    <div>
                      <div className="font-bold text-gray-400 mb-3">Just tap the Share Icon: </div>
                      <div className="mb-3">
                        <div className="rounded-full overflow-hidden bg-gray-50 p-2 inline-block">
                          <img className="h-4 w-4 bg-gray-50" src={iosShareIcon} alt="share icon" />
                        </div>
                      </div>

                      <div className="font-bold text-gray-400">Then tap "Add To Home Screen".</div>
                    </div>
                  ) : (
                    <div>
                      <div className="flex">
                        <span className="font-bold text-gray-400 self-center mr-3">Tap the menu icon ( 3 dots in the upper right corner) and tap "Add To Home Screen".</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
