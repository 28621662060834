import appStoreBadge from 'assets/appStoreBadge.svg';

export default function Pricing() {
  return (
    <div>
      <div>
        <div className="bg-gray-900">
          <div className="max-w-7xl mx-auto text-center px-4 md:px-6 lg:px-8 pb-8 md:pb-12 lg:pb-16 pt-12">
            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
              <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">Pricing</h2>
              <p className="text-3xl font-extrabold text-fryellow md:text-4xl lg:text-5xl">Good news! It's Cheap!</p>
              <p className="text-xl text-gray-300"></p>
              <p className="text-xl text-gray-300"></p>
            </div>
          </div>
        </div>
        <div className="pb-12 md:pb-24 lg:pb-32">
          <div className="relative">
            <div className="absolute inset-0 h-3/4 bg-gray-900" />
            <div className="relative z-10 px-6 flex justify-center align-center">
              <div className="flex flex-col bg-white rounded-xl shadow-lg overflow-hidden mb-6 md:mb-0 px-6">
                <div className="py-12 px-6 sm:px-12">
                  <div className="md:grid grid-cols-1 gap-6">
                    <div className="text-center">
                      <div className="block mb-6">
                        <div className="inline-block">
                          <h3 className="px-4 py-1 rounded-full text-base font-bold tracking-wide uppercase bg-blue-100 text-blue-600">On The Web</h3>
                        </div>
                      </div>
                      <div className="text-center inline-block text-6xl font-extrabold">Free!</div>
                      <div className="ml-1 text-2xl font-medium text-gray-500">no catches</div>
                    </div>
                    <p className="text-xl text-frpink text-center font-bold">Works great everywhere!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
