import { Feed } from 'model';
import { Link } from 'react-router-dom';

type Props = {
  feed: Feed;
};

export default function FeedListItem(props: Props) {
  return (
    <li className="p-6 border-solid">
      <div className="flex justify-start items-center">
        <div>
          <div className="">
            <Link to={`/feed/${props.feed.id}`} className=" hover:text-frblue-600 main-text">
              {props.feed.title}
            </Link>
          </div>
          <div className="flex justify-start detail-text mt-1.5">
            <span>{props.feed.rssUrl}</span>
          </div>
        </div>
      </div>
    </li>
  );
}
