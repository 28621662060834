import { Fragment, useEffect, useState } from 'react';
import DropZone from 'react-dropzone';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, ExclamationCircleIcon, PlusIcon, XIcon } from '@heroicons/react/outline';
import { useAppDispatch } from 'app/hooks';
import { addFeedByUrl, clearState, getFeeds, getHighlightedWords, getReplacementWords, getSignedInUser, putUser, resetFeedRequestState, setIsImport } from 'model/feedSlice';
import { useSelector } from 'react-redux';
import Spinner from 'assets/spinner-white-purple.gif';
import { ViewType, WordReplacement } from 'model';
import { js2xml, xml2js } from 'xml-js';
import { useHistory } from 'react-router-dom';
import Header from './Header';
import MainContent from './MainContent';
import prependHttp from 'prepend-http';
import { useCookies } from 'react-cookie';
import ManageTags from './ManageTags';

type FeedInfo = {
  xmlurl: string;
  title: string;
  status: 'idle' | 'loading' | 'failed' | 'success';
};

const getUrlsFromObject = (obj: any, feedInfoArray: FeedInfo[]) => {
  let children = obj['elements'];
  if (children && children.length > 0) {
    for (const child of children) {
      let attributes = child['attributes'];
      if (attributes) {
        let type = attributes['type'];
        let xmlurl = attributes['xmlUrl'];

        if (type === 'rss' && xmlurl) {
          feedInfoArray.push({ xmlurl: xmlurl, title: attributes['title'], status: 'idle' } as FeedInfo);
        }
      }

      if (child['elements']) {
        feedInfoArray = feedInfoArray.concat(getUrlsFromObject(child, []));
      }
    }
  }
  return feedInfoArray;
};

export default function Settings() {
  const [cookies, setCookie, removeCookie] = useCookies(['lukemisto']);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const user = useSelector(getSignedInUser);
  const highlightedWordsString = useSelector(getHighlightedWords);
  const [highlightedWordsArray, setHighlightedWordsArray] = useState<string[]>(highlightedWordsString ? highlightedWordsString.split(',') : []);

  const replacementdWordsString = useSelector(getReplacementWords);
  const [replacementWordsArray, setReplacementWordsArray] = useState<WordReplacement[]>(replacementdWordsString ? JSON.parse(replacementdWordsString) : []);

  const [newKeyword, setNewKeyword] = useState('');
  const [originalWord, setOriginalWord] = useState('');
  const [replaceWord, setReplaceWord] = useState('');

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [importedFeedInfo, setImportedFeedInfo] = useState<FeedInfo[]>([]);
  const [showFeedImportResults, setShowFeedImportResults] = useState(false);
  const [importStatus, setImportStatus] = useState<'not started' | 'in progress' | 'complete'>('not started');
  const [showKeywordDialog, setShowKeywordDialog] = useState(false);
  const [showReplacementDialog, setShowReplacementDialog] = useState(false);

  const [isHighlight, setIsHighlight] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const feedState = useSelector(getFeeds);

  const signOut = async () => {
    localStorage.clear();
    removeCookie('lukemisto');
    await dispatch(clearState());
    history.push('/');
  };

  const onFileChange = async (file: File) => {
    setSelectedFile(file);
    const read = new FileReader();

    read.readAsBinaryString(file);

    read.onloadend = async function () {
      const jsonObj = xml2js(read.result as string);
      const importedFeeds = getUrlsFromObject(jsonObj, []);

      setImportStatus('not started');
      setImportedFeedInfo(importedFeeds);
      setShowFeedImportResults(importedFeeds.length > 0);
    };
  };

  const handleRemoveFeed = async (index: number) => {
    let importedFeeds = importedFeedInfo;
    importedFeeds.splice(index, 1);

    setImportedFeedInfo([...importedFeeds]);
  };

  const handleCompleteImport = async () => {
    if (user && user.email) {
      setImportStatus('in progress');
      dispatch(setIsImport(true));
      let ifo = [...importedFeedInfo];
      for (const feedInfo of ifo) {
        feedInfo.status = 'loading';
        dispatch(addFeedByUrl({ rssUrl: prependHttp(feedInfo.xmlurl), userId: user.email }));
      }
      setImportedFeedInfo(ifo);
    }
  };

  const handleAddKeyword = () => {
    if (newKeyword.length > 0) {
      setHighlightedWordsArray([...highlightedWordsArray, newKeyword]);
      setShowKeywordDialog(false);
      setNewKeyword('');
    }
  };

  const handleRemoveKeyword = (keyword: string) => {
    setHighlightedWordsArray(highlightedWordsArray.filter((str) => str !== keyword));
  };

  const handleAddReplacementWord = () => {
    if (originalWord.length > 0 && replaceWord.length > 0) {
      setReplacementWordsArray([...replacementWordsArray, { original: originalWord, replace: replaceWord } as WordReplacement]);
      setShowReplacementDialog(false);
      setOriginalWord('');
      setReplaceWord('');
    }
  };

  const handleRemoveReplacementWord = (wr: WordReplacement) => {
    setReplacementWordsArray(replacementWordsArray.filter((item) => item.original !== wr.original && item.replace !== wr.replace));
  };

  useEffect(() => {
    if (feedState.user && !isFirstLoad) {
      let user = Object.assign({}, feedState.user);
      let updatedHighlightedWordsString = highlightedWordsArray.join();
      let updatedReplaceWordsString = JSON.stringify(replacementWordsArray);

      let shouldUpdate = false;
      if (user.highlightedWords !== updatedHighlightedWordsString) {
        shouldUpdate = true;
        user.highlightedWords = highlightedWordsArray.join();
      }
      if (user.replacementWords !== updatedReplaceWordsString) {
        shouldUpdate = true;
        user.replacementWords = updatedReplaceWordsString;
      }

      if (shouldUpdate) {
        shouldUpdate = false;
        dispatch(putUser(user));
      }
    } else {
      setIsFirstLoad(false);
    }
  }, [highlightedWordsArray, replacementWordsArray, dispatch, feedState]);

  const handleExport = () => {
    let outlineArray: any[] = [];
    for (const feed of feedState.feeds) {
      outlineArray.push({
        _attributes: {
          type: 'rss',
          xmlUrl: feed.rssUrl,
          text: feed.title,
          title: feed.title
        }
      });
    }

    let exportJson = {
      _declaration: { _attributes: { version: '1.0', encoding: 'utf-8' } },
      opml: {
        _attributes: {
          version: '1.0'
        },
        head: {
          title: "Let's Read Some Stuff Export"
        },
        body: {
          outline: {
            _attributes: {
              text: 'all',
              title: 'all'
            },
            outline: outlineArray
          }
        }
      }
    };

    const xml = js2xml(exportJson, {
      compact: true
    });

    var filename = 'export.opml';
    var pom = document.createElement('a');
    var bb = new Blob([xml], { type: 'text/plain' });

    pom.setAttribute('href', window.URL.createObjectURL(bb));
    pom.setAttribute('download', filename);

    pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
    pom.draggable = true;
    pom.classList.add('dragout');

    pom.click();
  };

  const resetFeedImport = async () => {
    setShowFeedImportResults(false);
    setSelectedFile(undefined);
    setImportedFeedInfo([]);
  };

  useEffect(() => {
    let shouldUpdate = false;
    let isComplete = true;

    if (feedState.feedRequestState && feedState.feedRequestState.rssUrl) {
      let ifo = [...importedFeedInfo];
      for (const feedInfo of ifo) {
        if (feedInfo.status !== 'success' && feedInfo.status !== 'failed') {
          isComplete = false;
        }
        if (feedInfo.xmlurl === feedState.feedRequestState.rssUrl && feedInfo.status !== feedState.feedRequestState.status) {
          shouldUpdate = true;
          feedInfo.status = feedState.feedRequestState.status;
        }
      }

      dispatch(setIsImport(!isComplete));

      if (shouldUpdate) {
        setImportedFeedInfo(ifo);
      }
      if (importStatus !== 'complete' && isComplete) {
        setImportStatus('complete');
      }
    }

    if (!selectedFile && feedState.feedRequestState && feedState.feedRequestState.rssUrl) {
      dispatch(resetFeedRequestState());
    }
  }, [feedState, importedFeedInfo, selectedFile, dispatch, setImportedFeedInfo, importStatus, setImportStatus]);

  return (
    <div className="sm:p-6">
      <Header title="Settings" viewType={ViewType.SETTINGS} />
      <MainContent
        content={
          <div>
            <div className="border-b border-gray-800">
              <div className="px-6 py-12">
                <div className="flex items-center gap-6 mb-3">
                  <div className="main-text">Highlighted Keywords</div>
                  <button
                    className="icon-button-container"
                    onClick={() => {
                      setIsHighlight(true);
                      setShowKeywordDialog(true);
                    }}
                  >
                    <PlusIcon className="icon" />
                  </button>
                </div>
                <div className="mb-6 text-base text-gray-400">
                  Gottalotta feeds to sort through? Set keywords to <span className="text-fryellow font-medium">HIGHLIGHT</span> the important stuff.
                </div>
                <div className="bg-gray-800 p-6 rounded-xl">
                  {highlightedWordsArray && highlightedWordsArray.length > 0 ? (
                    <div className="flex gap-3 flex-wrap">
                      {highlightedWordsArray?.map((keyword: string, index) => (
                        <div key={`${keyword}_${index}`} className="flex items-center gap-3 px-3 py-1.5 rounded-full bg-gray-700 text-gray-100">
                          <div>{keyword}</div>
                          <button className="rounded-full h-5 w-5 bg-gray-800 hover:bg-gray-600 p-1" onClick={() => handleRemoveKeyword(keyword)}>
                            <XIcon />
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="main-text">No Highlighted Keywords</div>
                  )}
                </div>
              </div>
            </div>

            <div className="border-b border-gray-800">
              <div className="px-6 py-12">
                <div className="flex items-center gap-6 mb-3">
                  <div className="main-text">Replacement Keywords</div>
                  <button
                    className="icon-button-container"
                    onClick={() => {
                      setShowReplacementDialog(true);
                    }}
                  >
                    <PlusIcon className="icon" />
                  </button>
                </div>
                <div className="mb-6 text-base text-gray-400">
                  Something (or someone) out there you can't stand? <span className="line-through font-medium text-fryellow">Burn it down</span>
                  <span className="font-medium text-fryellow">&nbsp;&#10132;&nbsp;REPLACE IT</span> with something more palatable.
                </div>
                <div className="bg-gray-800 p-6 rounded-xl">
                  {replacementWordsArray && replacementWordsArray.length > 0 ? (
                    <div className="flex gap-3 flex-wrap">
                      {replacementWordsArray?.map((replacement: WordReplacement, index) => (
                        <div key={`${replacement.original}_${index}`} className="flex items-center gap-3 px-3 py-1.5 rounded-full bg-gray-700 text-gray-100">
                          <div>
                            <span className="line-through">{replacement.original}</span>
                            <span>&nbsp;&#10132;&nbsp;{replacement.replace}</span>
                          </div>
                          <button
                            className="rounded-full h-5 w-5 bg-gray-800 hover:bg-gray-600 p-1"
                            onClick={() => {
                              handleRemoveReplacementWord(replacement);
                            }}
                          >
                            <XIcon />
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="main-text">No Replacement Keywords</div>
                  )}
                </div>
              </div>
            </div>

            <div className="border-b border-gray-800">
              <div className="px-6 py-12">
                <ManageTags />
              </div>
            </div>

            <div className="border-b border-gray-800">
              <div className="px-6 py-12">
                <div className="flex items-center gap-6 mb-6">
                  <div className="main-text">Export OPML File</div>
                </div>
                <div>
                  <button onClick={handleExport} className="pushable bg-frblue-700 rounded-xl w-full sm:w-auto">
                    <span className="front bg-frblue button-text rounded-xl px-12 py-3 w-full">Export Now</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="border-b border-gray-800">
              <div className="px-6 py-12">
                <div className="main-text mb-6">Import OPML File</div>
                <DropZone onDrop={(acceptedFiles) => onFileChange(acceptedFiles[0])}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="p-6 border-4 border-gray-400 border-dashed rounded-xl" {...getRootProps()}>
                      <div className="text-center">
                        <div className="sm:flex justify-center value-text">
                          <label htmlFor="file-upload" className="relative cursor-pointer font-bold text-frpink hover:text-frpink-400 focus:outline-none" onClick={(e) => e.stopPropagation()}>
                            <span>Click to upload a file</span>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only bg-red-600"
                              value=""
                              onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                  onFileChange(e.target.files[0]);
                                }
                              }}
                              {...getInputProps()}
                            />
                          </label>
                          <p className="pl-1 text-fryellow font-bold  ">or drag and drop</p>
                        </div>
                      </div>
                    </div>
                  )}
                </DropZone>
              </div>
              {selectedFile && importedFeedInfo.length === 0 && <div className="main-text mb-3">No Feeds Found</div>}
            </div>
            <div className="border-b border-gray-800 px-6 py-12">
              <div className="main-text mb-6">Chrome Extension</div>
              <div className="text-base font-bold">
                <a href="https://chrome.google.com/webstore/detail/save-to-lukemisto/eohaglmmfjppndjhmelfknchipgfipgj" target="_blank" className="text-frblue hover:underline hover:text-frblue-600">
                  Get it in the Chrome Web Store →
                </a>
              </div>
            </div>
            <div className="border-b border-gray-800 px-6 py-12">
              <div className="main-text mb-6">Get Support</div>
              <div className="text-base font-bold">
                <span className="text-fryellow">Email: </span>
                <a className="text-frblue hover:underline hover:text-frblue-600" href="mailto:support@mkesuperdigital.com">
                  support@mkesuperdigital.com
                </a>
              </div>
            </div>
            <div className="px-6 py-12">
              <div>
                <div className="w-full sm:w-auto">
                  <button onClick={signOut} className="pushable bg-frpink-700 rounded-xl w-full sm:w-auto">
                    <span className="front bg-frpink button-text rounded-xl px-12 py-3 w-full">Sign Out</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      <Transition.Root show={showReplacementDialog} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setShowReplacementDialog(false)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-gray-900 rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm w-full sm:p-6">
                <div className="block mb-6">
                  <div className="mb-6">
                    <label className="label-text">Add New Replacment</label>
                  </div>
                  <div className="text-white text-sm font-medium mb-1">Original Word</div>
                  <input
                    type="text"
                    name="feed-url"
                    id="feed-url"
                    className="input-text input-background input-outline rounded-xl overflow-hidden input-border mb-3"
                    placeholder="Word to replace"
                    onChange={(event) => {
                      setOriginalWord(event.target.value);
                    }}
                    value={originalWord}
                  />

                  <div className="text-white text-sm font-medium mb-1">Replacment Word</div>
                  <input
                    type="text"
                    name="feed-url"
                    id="feed-url"
                    className="input-text input-background input-outline rounded-xl overflow-hidden input-border mb-6"
                    placeholder="Replace with this word"
                    onChange={(event) => {
                      setReplaceWord(event.target.value);
                    }}
                    value={replaceWord}
                  />
                </div>
                <div className="block">
                  <button
                    onClick={() => {
                      handleAddReplacementWord();
                    }}
                    className="pushable bg-frpink-700 rounded-xl w-full"
                  >
                    <span className="front bg-frpink button-text rounded-xl px-6 py-3 w-full">Save It</span>
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={showKeywordDialog} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setShowKeywordDialog(false)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-gray-900 rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm w-full sm:p-6">
                <div className="block mb-6">
                  <div className="mb-3">
                    <label className="label-text">Add New Keyword</label>
                  </div>
                  <input
                    type="text"
                    name="feed-url"
                    id="feed-url"
                    className="input-text input-background input-outline rounded-xl overflow-hidden input-border"
                    placeholder="Keyword"
                    onChange={(event) => {
                      setNewKeyword(event.target.value);
                    }}
                    value={newKeyword}
                  />
                </div>
                <div className="block">
                  <button
                    onClick={() => {
                      if (isHighlight) {
                        handleAddKeyword();
                      } else {
                        handleAddReplacementWord();
                      }
                    }}
                    className="pushable bg-frpink-700 rounded-xl w-full"
                  >
                    <span className="front bg-frpink button-text rounded-xl px-6 py-3 w-full">Save It</span>
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={showFeedImportResults} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => resetFeedImport()}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-gray-900 rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl w-full sm:p-6">
                <div>
                  <div className="main-text mb-3">Here are the feeds we found:</div>
                  {importedFeedInfo.length === 0 && importStatus !== 'complete' && (
                    <div className="text-base font-bold">
                      <span className="text-fryellow">I don't see anything to import. If you think this is a mistake you should email </span>
                      <a className="text-frblue hover:underline hover:text-frblue-600" href="mailto:support@mkesuperdigital.com">
                        support@mkesuperdigital.com
                      </a>
                      .
                    </div>
                  )}
                  <ul className="divide-y divide-gray-700 divide-solid rounded-xl overflow-hidden">
                    {importedFeedInfo.map((feedInfo, index) => (
                      <li key={index} className="p-6 bg-fryellow flex justify-between items-center">
                        <div>
                          <div className="text-frblue-900 text-base font-bold">{feedInfo.title}</div>
                          <div className="text-frblue-900 text-sm font-medium">{feedInfo.xmlurl}</div>
                        </div>
                        <div>
                          {feedInfo.status === 'idle' && (
                            <button className="icon-button-container" onClick={() => handleRemoveFeed(index)}>
                              <XIcon className="icon" />
                            </button>
                          )}
                          {feedInfo.status === 'loading' && (
                            <div className="bg-frpurple rounded-full p-1.5">
                              <img className="h-6 w-6" src={Spinner} alt="spinner" />
                            </div>
                          )}
                          {feedInfo.status === 'success' && (
                            <div className="bg-green-500 rounded-full p-1.5">
                              <CheckIcon className="h-6 w-6 text-white" />
                            </div>
                          )}
                          {feedInfo.status === 'failed' && (
                            <div className="bg-red-500 rounded-full p-1.5">
                              <ExclamationCircleIcon className="h-6 w-6 text-white" />
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="block mt-6">
                    {importStatus !== 'complete' && importedFeedInfo.length > 0 && (
                      <button onClick={handleCompleteImport} disabled={importStatus !== 'not started'} className="pushable bg-frpink-700 rounded-xl w-full">
                        <span className="front bg-frpink button-text rounded-xl px-6 py-3 w-full">
                          {importStatus === 'not started' && 'Complete Import'}
                          {importStatus === 'in progress' && 'Import In Progress'}
                        </span>
                      </button>
                    )}
                    {(importStatus === 'complete' || importedFeedInfo.length === 0) && (
                      <button onClick={resetFeedImport} className="pushable bg-frpurple-700 rounded-xl w-full">
                        <span className="front bg-frpurple button-text rounded-xl px-6 py-3 w-full">Dismiss</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
