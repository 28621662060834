import { FeedItem, WordReplacement } from 'model';
import dateformat from 'dateformat';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFeeds, getReplacementWords, getSignedInUser, postSavedItem, SavedItemsPostParams } from 'model/feedSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { useAppDispatch } from 'app/hooks';

type Props = {
  feedItem: FeedItem;
};

export default function FeedItemListItem(props: Props) {
  const dispatch = useAppDispatch();
  const { feedItem } = props;
  const feedState = useSelector(getFeeds);
  const [hideImage, setHideImage] = useState(false);

  const replacementdWordsString = useSelector(getReplacementWords);
  const replacementWordsArray: WordReplacement[] = replacementdWordsString ? JSON.parse(replacementdWordsString) : [];

  let feedItemTitle = feedItem.title;

  if (typeof feedItem.title !== 'string') {
    feedItemTitle = feedItem.contentSnippet ? feedItem.contentSnippet.substring(0, 80) : feedItem.feedTitle;
  }

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: feedItemTitle,
          url: feedItem.link
        })
        .then(() => console.log('Share was successful.'))
        .catch((error) => console.log('Sharing failed', error));
    }
  };

  const user = useSelector(getSignedInUser);

  const handleSave = () => {
    if (user) {
      dispatch(postSavedItem({ userId: user.email, url: feedItem.link, title: feedItem.title, feedTitle: feedItem.feedTitle, imageSrc: feedItem.imageUrl } as SavedItemsPostParams));
    }
  };

  let imageSrc = feedItem.imageUrl;

  const getMeta = (url: string) => {
    const img = new Image();
    img.addEventListener('load', function () {
      if (this.naturalHeight < 200) {
        setHideImage(true);
      }
    });
    img.src = url;
  };

  let extension;
  if (imageSrc && imageSrc?.length > 0) {
    getMeta(imageSrc);
    extension = imageSrc.split('.').pop();
  }

  let itemTitle = feedItemTitle + '';
  const keywords = feedState.user?.highlightedWords ? feedState.user?.highlightedWords.split(',') : [];
  if (keywords && keywords.length > 0) {
    let filteredKeywords = keywords.filter((substring) => feedItemTitle.toLowerCase().includes(substring.toLowerCase()));
    for (const keyword of filteredKeywords) {
      var reg = new RegExp(keyword, 'ig');
      itemTitle = itemTitle.replaceAll(reg, `<span class="highlight-text font-bold">${keyword.toUpperCase()}</span>`);
    }
  }

  if (replacementWordsArray.length > 0) {
    let filteredReplacements = replacementWordsArray.filter((item) => feedItemTitle.toLowerCase().includes(item.original.toLowerCase()));
    for (const replacement of filteredReplacements) {
      reg = new RegExp(`\\b${replacement.original}\\b`, 'ig');
      itemTitle = itemTitle.replaceAll(reg, replacement.replace);
    }
  }

  return (
    <li className="w-full">
      <div className={`p-6 w-full border-solid ` + (feedItem.status === 'read' ? 'opacity-40' : 'opacity-100')}>
        <div className="sm:mb-0 flex gap-6">
          {imageSrc && extension && !hideImage && (
            <div className="hidden sm:flex h-24 w-36 overflow-hidden rounded-xl flex-none">
              <LazyLoadImage className="w-full h-full object-cover overflow-hidden" src={imageSrc} />
            </div>
          )}
          <div className="flex-grow">
            <a className="hover:text-frblue main-text" href={feedItem.link} target="_blank" rel="noreferrer">
              <div dangerouslySetInnerHTML={{ __html: itemTitle }}></div>
            </a>
            <div className="mt-3 hidden sm:block">
              <div className="detail-text flex gap-6 items-center">
                {feedItem.feedTitle && <div className="font-bold max-w-xs">{feedItem.feedTitle}</div>}
                {feedItem.author && <div>{feedItem.author}</div>}
                <div className="self-start">{dateformat(feedItem.pubDate, 'mm/dd/yyyy h:MM TT')}</div>
              </div>
            </div>
          </div>
          {imageSrc && extension && !hideImage && (
            <div className="sm:hidden h-16 w-20 overflow-hidden rounded-xl flex-none">
              <LazyLoadImage className="w-full h-full object-cover overflow-hidden" src={imageSrc} />
            </div>
          )}
          <Menu as="div" className="hidden sm:flex relative inline-block text-left">
            <div>
              <Menu.Button className="flex items-center rounded-full bg-gray-800 hover:bg-gray-700 text-gray-400 p-1 focus:outline-none">
                <span className="sr-only">Open options</span>
                <DotsVerticalIcon className="h-7 w-7" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-gray-200 focus:outline-none">
                <Menu.Item>
                  <button className="block hover:bg-gray-300 cursor-pointer rounded-t-lg w-full" onClick={handleSave}>
                    <div className="text-left p-3 font-medium text-gray-900">Save</div>
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button className="block hover:bg-gray-300 cursor-pointer rounded-b-lg w-full" onClick={handleShare}>
                    <div className="text-left p-3 font-medium text-gray-900">Share</div>
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="mt-3 flex sm:hidden justify-between items-end w-full">
          <div className="sm:flex justify-start gap-3 detail-text">
            {feedItem.feedTitle && <span className="font-bold">{feedItem.feedTitle}</span>}
            {feedItem.author && <div>{feedItem.author}</div>}
            <div className="mt-3">{dateformat(feedItem.pubDate, 'mm/dd/yyyy h:MM TT')}</div>
          </div>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex items-center rounded-full bg-gray-800 hover:bg-gray-700 text-gray-400 p-1 focus:outline-none">
                <span className="sr-only">Open options</span>
                <DotsVerticalIcon className="h-7 w-7" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-gray-200 focus:outline-none">
                <Menu.Item>
                  <button className="block hover:bg-gray-300 cursor-pointer rounded-t-lg w-full" onClick={handleSave}>
                    <div className="text-left p-3 font-medium text-gray-900">Save</div>
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button className="block hover:bg-gray-300 cursor-pointer rounded-b-lg w-full" onClick={handleShare}>
                    <div className="text-left p-3 font-medium text-gray-900">Share</div>
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </li>
  );
}
