import axios from 'axios';

const FeedReaderApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY ?? 'loDChujR2P8jxTCcY7PCl29JwtZ1V3TVaZJ7ecw0'
  }
});

export { FeedReaderApi };
