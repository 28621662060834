import { useEffect, useState } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFeeds } from 'model/feedSlice';
import ActionButtons from './ActionButtons';
import { ViewType } from 'model';
import logo192 from 'assets/logo192.png';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Nav() {
  const location = useLocation();

  // @ts-ignore
  let { feedId } = useParams();

  const [currentPathName, setCurrentPathName] = useState(location.pathname);
  const [openPanel, setOpenPanel] = useState(false);

  useEffect(() => {
    if (currentPathName !== location.pathname) {
      setOpenPanel(false);
      setCurrentPathName(location.pathname);
    }
  }, [location, currentPathName, setCurrentPathName]);

  const navigation = [
    { name: 'All', href: '/all', current: location.pathname === '/all' },
    { name: 'Feeds', href: '/feeds', current: location.pathname === '/feeds' },
    { name: 'Saved', href: '/saved', current: location.pathname === '/saved' },
    { name: 'Settings', href: '/settings', current: location.pathname === '/settings' }
  ];

  const feedState = useSelector(getFeeds);
  const feed = feedState.feeds.find((feed) => {
    return feed.id === feedId;
  });

  let currentViewType = ViewType.UNKNOWN;

  let title = '';
  switch (location.pathname) {
    case '/all':
      currentViewType = ViewType.ALL;
      title = 'All Items';
      break;
    case '/feeds':
      currentViewType = ViewType.FEEDS;
      title = 'Feeds';
      break;
    case '/saved':
      currentViewType = ViewType.SAVED;
      title = 'Saved';
      break;
    case '/settings':
      currentViewType = ViewType.SETTINGS;
      title = 'Settings';
      break;
    default:
      currentViewType = ViewType.DETAIL;
      title = feed ? feed.title : 'unknown';
  }

  return (
    <div className="sticky top-0 left-0 w-full z-40">
      <nav className="bg-gray-900 border-b border-gray-900 border-solid">
        <div className="max-w-5xl mx-auto p-6">
          <div className="flex justify-between sm:hidden">
            {/* Mobile menu button */}
            <button
              onClick={() => setOpenPanel(!openPanel)}
              className="bg-gray-700 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-frblue"
            >
              <span className="sr-only">Open main menu</span>
              {openPanel ? <XIcon className="block h-6 w-6" aria-hidden="true" /> : <MenuIcon className="block h-6 w-6" aria-hidden="true" />}
            </button>
            <div className="sm:hidden">
              <h1 className="header-text text-right">{title}</h1>
            </div>
          </div>
          <div className="flex sm:hidden justify-between gap-6 mt-6 sm:mt-0 ">
            <ActionButtons viewType={currentViewType} showToggle={currentViewType === ViewType.ALL || currentViewType === ViewType.SAVED} />
          </div>
          <div className="flex justify-between">
            {/* Desktop Nav Links */}
            <div className="hidden sm:flex">
              {navigation.map((item) => (
                <Link key={item.name} to={item.href} className={classNames(item.current ? 'nav-item-active' : 'nav-item-inactive', 'nav-item mr-3')} aria-current={item.current ? 'page' : undefined}>
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden sm:flex">
              <img className="rounded-xl overflow-hidden w-12" src={logo192} alt="lukemisto logo" />
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        {openPanel && (
          <div className="sm:hidden bg-gray-800">
            <div className="">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-700 border-frblue-600 text-frblue' : 'border-transparent text-gray-200 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                    'block pl-6 pr-6 py-3 border-l-4 text-lg font-semibold border-solid'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        )}
      </nav>
    </div>
  );
}
