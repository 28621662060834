import { useAppDispatch } from 'app/hooks';
import { FeedRequestParams, getSignedInUser, markAllRead, requestMarkAllRead } from 'model/feedSlice';
import { useSelector } from 'react-redux';

export type Props = {
  feedId: string;
};

export default function MarkAllReadButton(props: Props) {
  const dispatch = useAppDispatch();
  const user = useSelector(getSignedInUser);

  const handleMarkAllRead = () => {
    dispatch(markAllRead(props.feedId));
    dispatch(requestMarkAllRead({ feedRefId: props.feedId, userId: user?.email } as FeedRequestParams));
  };

  return (
    <div className="w-full px-6 py-12 sm:py-6 border-t border-gray-700">
      <button onClick={handleMarkAllRead} className="pushable bg-frpink-700 rounded-xl w-full sm:w-auto">
        <span className="front bg-frpink button-text rounded-xl px-6 py-3 w-full sm:w-auto">Mark All As Read</span>
      </button>
    </div>
  );
}
