import { useSelector } from 'react-redux';
import { Feed, ViewType } from 'model';
import { FeedListItem } from 'components';
import { getFeeds } from 'model/feedSlice';
import { useEffect, useState } from 'react';
import Header from './Header';
import MainContent from './MainContent';
import EmptyState from './EmptyState';

export default function FeedList() {
  const feedState = useSelector(getFeeds);

  const initialFeeds: Feed[] = [];
  const [feeds, setFeeds] = useState(initialFeeds);

  useEffect(() => {
    setFeeds(feedState.feeds);
  }, [feedState]);

  return (
    <div className="sm:p-6">
      <Header title="Feeds" viewType={ViewType.FEEDS} />
      <MainContent content={<div>{feeds.length === 0 ? <EmptyState /> : <ul className="list">{feeds && feeds.map((feed, index) => <FeedListItem key={index} feed={feed} />)}</ul>}</div>} />
    </div>
  );
}
