import { Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { useSelector } from 'react-redux';
import { getFeeds } from 'model/feedSlice';

export default function Toasts() {
  const feedState = useSelector(getFeeds);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (feedState.toast) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [feedState]);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-fryellow shadow-lg rounded-xl pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-center justify-between">
                  <div className="">
                    <div className="main-toast-text">{feedState.toast?.message}</div>
                    <div className="text-sm text-gray-900 font-semibold">{feedState.toast?.details.toString()}</div>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-frpurple rounded-full inline-flex text-gray-50 hover:bg-frpurple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 p-1.5"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
