import { EmojiSadIcon, FlagIcon, BookmarkIcon, TagIcon } from '@heroicons/react/solid';

export default function Features() {
  const features = [
    {
      name: 'Keyword Highlighting',
      icon: FlagIcon,
      content: (
        <div>
          {' '}
          <div>Gottalotta feeds to sort through?</div>
          <div>
            Set keywords to <span className="text-fryellow font-medium">HIGHLIGHT</span> the important stuff.
          </div>
        </div>
      )
    },
    {
      name: 'Keyword Replacement',
      icon: EmojiSadIcon,
      content: (
        <div>
          <div>Something (or someone) out there you can't stand?</div>
          <span className="line-through font-medium text-fryellow">Burn it down</span>
          <span className="font-medium text-fryellow">&nbsp;&#10132;&nbsp;REPLACE IT</span> with something more palatable.
        </div>
      )
    },
    {
      name: 'Chrome Extension',
      icon: BookmarkIcon,
      content: (
        <div>
          <div>There's a whole world of content outside your RSS feeds.</div>
          <span className="font-medium text-fryellow">SAVE IT</span> and read it later (or don't).
          <div className="mt-3">
            <a href="https://chrome.google.com/webstore/detail/save-to-lukemisto/eohaglmmfjppndjhmelfknchipgfipgj" target="_blank" className="text-frblue hover:underline hover:text-frblue-600">
              Get it in the Chrome Web Store →
            </a>
          </div>
        </div>
      )
    },
    {
      name: 'Tags',
      icon: TagIcon,
      content: (
        <div>
          <div>Got a lotta stuff you saved for later?</div>
          <span>Don't overthink it!&nbsp;</span>
          <span className="font-medium text-fryellow">TAG IT!</span>
          <span className="block">Then filter your saved items to find what you're looking for!</span>
        </div>
      )
    }
  ];

  return (
    <div className="relative to-slate-900 via-slate-800 from-frpurple-700 py-12 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-gray-200 uppercase">Extras</h2>
        <p className="mt-2 text-3xl font-extrabold text-fryellow tracking-tight sm:text-4xl">It's the little things that make a difference.</p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-400">You don't need currated lists or an algorithmically sorted feed to improve your reading experience.</p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-900 rounded-xl px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-frpink rounded-xl shadow-lg">
                        <feature.icon className="h-8 w-8 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-100 tracking-tight">{feature.name}</h3>
                    <div className="mt-3 text-gray-400">{feature.content}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
