import AppleSignin from 'react-apple-signin-auth';
import { useAppDispatch } from 'app/hooks';
import { postTestUser, postUser, UserParams, UserTestParams } from 'model/feedSlice';
import { useCookies } from 'react-cookie';

export default function SignInWithApple() {
  const dispatch = useAppDispatch();
  const [cookies, setCookie] = useCookies(['lukemisto']);

  return (
    <div>
      <div className="bg-gray-900 p-6 pb-12 sm:pb-6 sm:rounded-xl w-full">
        <div>
          <div className="text-lg font-bold text-fryellow mb-3 text-center">To Get Started</div>
          <div className="mx-auto flex justify-center">
            <AppleSignin
              authOptions={{
                clientId: 'com.lukemisto.web',
                scope: 'email name',
                redirectURI: process.env.REACT_APP_APPLE_REDIRECT!,
                state: 'state',
                nonce: 'nonce',
                usePopup: true
              }}
              noDefaultStyle={true}
              className="apple-auth-btn-custom"
              buttonExtraChildren={<span>Sign In With Apple</span>}
              onSuccess={(response: any) => {
                let expirationDate = new Date();
                expirationDate.setFullYear(expirationDate.getFullYear() + 5);
                if (response.authorization && response.authorization.id_token) {
                  setCookie('lukemisto', response, { path: '/', expires: expirationDate });
                  dispatch(postUser(response as UserParams));
                }
              }}
              onError={(error: any) => {
                console.error(error);
              }}
            />
          </div>
        </div>
        {process.env.NODE_ENV && process.env.NODE_ENV === 'development' && (
          <div
            className="cursor-pointer text-frpink text-center mt-3"
            onClick={() => {
              console.log();
              let response = { email: 'kevin@kevinstueber.com' };
              let expirationDate = new Date();
              expirationDate.setFullYear(expirationDate.getFullYear() + 5);
              setCookie('lukemisto', response, { path: '/', expires: expirationDate });
              dispatch(postTestUser(response as UserTestParams));
            }}
          >
            Dev Login
          </div>
        )}
        <div className="text-base text-gray-400 mt-3 text-center">No Lukemisto account? No problem.</div>
        <div className="text-base text-gray-400 text-center">We'll create one for you.</div>
      </div>
    </div>
  );
}
