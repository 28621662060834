import Spinner from 'assets/spinner-white-purple.gif';

export type Props = {
  message?: string;
};

export default function LoadingOverlay(props: Props) {
  let message = props.message ?? 'Working . . .';
  return (
    <div>
      <div className="w-full h-screen fixed top-0 left-0 bg-gray-900 opacity-75 z-50"></div>
      <div className="flex items-center justify-center w-full h-screen fixed top-0 left-0 flex-col z-50">
        <div className="bg-frpurple px-6 py-3 rounded-xl flex justify-between items-center gap-3">
          <img className="h-8 w-8" src={Spinner} alt="spinner" />
          <div className=" w-auto text-white font-semibold text-base">{message}</div>
        </div>
      </div>
    </div>
  );
}
