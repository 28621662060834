export type Feed = {
  id: string;
  icon: string;
  image: string;
  lastBuildDate: string;
  link: string;
  title: string;
  items: FeedItem[];
  rssUrl: string;
  refId: string;
};

export type FeedItem = {
  title: string;
  author: string;
  content: string;
  ['content:encoded']: string;
  contentSnippet: string;
  id: string;
  isoDate: string;
  link: string;
  pubDate: string;
  refId: string;
  feedId: string;
  feedTitle: string;
  status: string;
  isDisplayed: boolean;
  feedRefId: string;
  feedItemRefId: string;
  imageUrl: string;
};

export type SavedItem = {
  userId: string;
  url: string;
  title: string;
  feedTitle: string;
  imageSrc: string;
  status: string;
  updated: string;
  created: string;
  tags: string;
};

export enum ViewType {
  ALL,
  FEEDS,
  DETAIL,
  UNKNOWN,
  ALL_READ,
  SETTINGS,
  SAVED
}

export type Toast = {
  message: string;
  details: string;
};

export type User = {
  email: string;
  highlightedWords: string;
  replacementWords: string;
  created: number;
  tags: string;
};

export type WordReplacement = {
  original: string;
  replace: string;
};
