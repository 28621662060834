import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useAppDispatch } from 'app/hooks';
import { SavedItem } from 'model';
import { SavedItemsPutParams, getSignedInUser, putSavedItem } from 'model/feedSlice';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  open: boolean;
  setOpen: Function;
  savedItem: SavedItem;
};

export default function ManageTagsDialog(props: Props) {
  const { savedItem } = props;
  const user = useSelector(getSignedInUser);
  const fullTagsArray = user?.tags ? user.tags.split(',') : [];
  const [tagsArray, setTagsArray] = useState<string[]>(fullTagsArray);
  const [assignedTagsArray, setAssignedTagsArray] = useState<string[]>(savedItem.tags ? savedItem.tags.split(',') : []);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const dispatch = useAppDispatch();

  const handleRemoveTag = (tag: string) => {
    setAssignedTagsArray(assignedTagsArray.filter((str) => str !== tag));
  };

  const handleAddTag = (tag: string) => {
    setAssignedTagsArray([...assignedTagsArray, tag]);
  };

  useEffect(() => {
    if (!isFirstLoad) {
      let si = Object.assign({}, savedItem);
      let updatedTagsString = assignedTagsArray.join();

      let shouldUpdate = false;
      if (si.tags !== updatedTagsString) {
        shouldUpdate = true;
        si.tags = updatedTagsString;
      }
      if (shouldUpdate) {
        shouldUpdate = false;
        if (user) {
          dispatch(putSavedItem({ userId: user.email, url: si.url, status: si.status, tags: si.tags } as SavedItemsPutParams));
        }
      }
    } else {
      setIsFirstLoad(false);
    }

    setTagsArray(
      fullTagsArray.filter((tag) => {
        return !assignedTagsArray.includes(tag);
      })
    );
  }, [assignedTagsArray]);

  return (
    <Fragment>
      {/*start add feed modal*/}
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => props.setOpen(false)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-gray-900 rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm w-full sm:p-6">
                <div className="block">
                  <div className="mb-6">
                    <span className="header-text">Manage Item Tags</span>
                  </div>
                  <div className="mb-6">
                    <div className="label-text mb-3">Assigned Tags</div>
                    <div className="bg-gray-800 p-6 rounded-xl">
                      {assignedTagsArray && assignedTagsArray.length > 0 ? (
                        <div className="flex gap-3 flex-wrap">
                          {assignedTagsArray?.map((tag: string, index) => (
                            <div key={`${tag}_${index}`} className="flex items-center gap-3 px-3 py-1.5 rounded-full bg-gray-700 text-gray-100">
                              <div>{tag}</div>
                              <button className="rounded-full h-5 w-5 bg-gray-800 hover:bg-gray-600 p-1" onClick={() => handleRemoveTag(tag)}>
                                <XIcon />
                              </button>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="main-text">No Assigned Tags</div>
                      )}
                    </div>
                  </div>
                  <div className="mb-12">
                    <div className="label-text mb-3">Available Tags</div>
                    <div className="bg-gray-800 p-6 rounded-xl">
                      {tagsArray && tagsArray.length > 0 ? (
                        <div className="flex gap-3 flex-wrap">
                          {tagsArray?.map((tag: string, index) => (
                            <div key={`${tag}_${index}`} className="flex items-center gap-3 px-3 py-1.5 rounded-full bg-gray-700 text-gray-100 cursor-pointer" onClick={() => handleAddTag(tag)}>
                              <div>{tag}</div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="main-text">No Saved Item Tags</div>
                      )}
                    </div>
                  </div>
                  <div className="block">
                    <button onClick={() => props.setOpen(false)} className="pushable bg-frpink-700 rounded-xl w-full">
                      <span className="front bg-frpink button-text rounded-xl px-6 py-3 w-full">Dismiss</span>
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}
