import SignInWithApple from './SignInWithApple';

import logo192 from 'assets/logo192.png';

export default function ExtensionSignin() {
  return (
    <div>
      <div className="bg-gradient-to-tl to-slate-900 via-slate-800 from-frpurple-700">
        <div className="relative overflow-hidden">
          <div className="relative py-12">
            <div className="mx-auto max-w-3xl">
              <div>
                <div className="flex mb-6">
                  <div className="px-6 mx-auto">
                    <div>
                      <div>
                        <a href="/">
                          <img className="rounded-xl overflow-hidden w-20 mx-auto mb-6" src={logo192} alt="letsreadsomestuuff.com logo" />
                          <h1 className="text-4xl tracking-tight font-extrabold text-fryellow text-center sm:leading-none lg:text-5xl xl:text-6xl cartridge-bold">Lukemisto</h1>
                        </a>
                      </div>
                      <div className="mt-3 sm:mt-5 ">
                        <p className=" text-gray-300 text-xl text-left">
                          <span className="font-bold text-frpink">Sign in to start using our Chrome extension.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-16 sm:mt-24 md:mt-0 md:col-span-6 sm:px-6 w-full">
                  <SignInWithApple />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
