import { SavedItem, ViewType } from 'model';
import { getAllSavedItems, getFeeds } from 'model/feedSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EmptyState from './EmptyState';
import Header from './Header';
import MainContent from './MainContent';
import SavedItemListItem from './SavedItemListItem';

export default function SavedItems() {
  const savedItems = useSelector(getAllSavedItems);
  return (
    <div className="sm:p-6">
      <Header title="Saved Items" viewType={ViewType.SAVED} showToggle={true} />
      <MainContent
        content={
          <div>
            {savedItems.length === 0 ? <EmptyState /> : <ul className="list">{savedItems && savedItems.map((savedItem, index) => <SavedItemListItem key={index} savedItem={savedItem} />)}</ul>}
          </div>
        }
      />
    </div>
  );
}
