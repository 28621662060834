import { CheckIcon, PlusIcon, RefreshIcon, TrashIcon, FilterIcon } from '@heroicons/react/outline';
import { useAppDispatch } from 'app/hooks';
import { ViewType } from 'model';
import { deleteFeed, FeedRequestParams, getFeeds, getSignedInUser, markAllRead, refreshFeeds, requestMarkAllRead, requestSavedItems, SavedItemsRequestParams } from 'model/feedSlice';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Dialog, Switch, Transition } from '@headlessui/react';
import AddFeedDialog from './AddFeedDialog';
import FilterSavedItemsDialog from './FilterSavedItemsDialog';

type Props = {
  viewType: ViewType;
  showToggle?: boolean;
};

export default function ActionButtons(props: Props) {
  const { viewType, showToggle = false } = props;

  const dispatch = useAppDispatch();
  const user = useSelector(getSignedInUser);
  const [showAddFeed, setShowAddFeed] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showFilterSavedItems, setShowFilterSavedItems] = useState(false);
  const [enabled, setEnabled] = useState(false);

  // @ts-ignore
  let { feedId } = useParams();

  const feedState = useSelector(getFeeds);
  const feed = feedState.feeds.find((feed) => {
    return feed.id === feedId;
  });

  const handleConfirmDelete = () => {
    setShowConfirmDelete(true);
  };

  const handleDelete = () => {
    if (feed && feed.refId) {
      dispatch(deleteFeed({ feedRefId: feed.refId, userId: user?.email } as FeedRequestParams));
    }
  };

  const handleMarkAllRead = () => {
    setEnabled(false);
    const refId = feed && feed.refId ? feed.refId : 'all';
    dispatch(markAllRead(refId));
    dispatch(requestMarkAllRead({ feedRefId: refId, userId: user?.email } as FeedRequestParams));
  };

  const handleRefresh = () => {
    setEnabled(false);
    if (viewType !== ViewType.SAVED) {
      dispatch(refreshFeeds({ userId: user?.email } as FeedRequestParams));
    } else {
      dispatch(requestSavedItems({ userId: user?.email } as SavedItemsRequestParams));
    }
  };

  const toggleReadItems = (enabled: boolean) => {
    setEnabled(enabled);
  };

  useEffect(() => {
    if (viewType === ViewType.ALL) {
      if (enabled) {
        dispatch(refreshFeeds({ userId: user?.email, status: 'read' }));
      } else {
        dispatch(refreshFeeds({ userId: user?.email }));
      }
    } else {
      if (enabled) {
        dispatch(requestSavedItems({ userId: user?.email, status: 'archive' } as SavedItemsRequestParams));
      } else {
        dispatch(requestSavedItems({ userId: user?.email } as SavedItemsRequestParams));
      }
    }
  }, [enabled]);

  return (
    <Fragment>
      {showToggle && (
        <div className="flex gap-3 bg-gray-800 sm:bg-gray-900 p-3 rounded-full self-center">
          <div className="self-center text-sm font-medium text-gray-300 text-right">
            <div>Show {viewType === ViewType.ALL ? 'Read' : 'Archived'}</div>
          </div>
          <Switch
            checked={enabled}
            onChange={toggleReadItems}
            className={`${
              enabled ? 'bg-frblue' : 'bg-gray-400'
            } self-center inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${enabled ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 transform rounded-full bg-gray-200 shadow ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>
      )}
      <div className="flex gap-6">
        {viewType === ViewType.SAVED && (
          <button className="icon-button-container" onClick={() => setShowFilterSavedItems(true)}>
            <FilterIcon className="icon" />
          </button>
        )}
        {(viewType === ViewType.ALL || viewType === ViewType.DETAIL) && (
          <button className="icon-button-container" onClick={handleMarkAllRead}>
            <CheckIcon className="icon" />
          </button>
        )}
        {viewType !== ViewType.SETTINGS && (
          <button className="icon-button-container" onClick={handleRefresh}>
            <RefreshIcon className="icon" />
          </button>
        )}
        {viewType === ViewType.DETAIL && (
          <button className="icon-button-container" onClick={handleConfirmDelete}>
            <TrashIcon className="icon" />
          </button>
        )}
        {viewType === ViewType.FEEDS && (
          <button className="icon-button-container" onClick={() => setShowAddFeed(true)}>
            <PlusIcon className="icon" />
          </button>
        )}
      </div>
      {/*start confirm delete modal*/}
      <Transition.Root show={showConfirmDelete} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setShowConfirmDelete(false)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-gray-900 rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm w-full sm:p-6">
                <div className="block mb-6">
                  <div className="label-text">Are you sure?</div>
                  <div className="label-text">Some things cannot be undone . . .</div>
                </div>
                <div className="block mb-3">
                  <button onClick={handleDelete} className="pushable bg-frpink-700 rounded-xl w-full">
                    <span className="front bg-frpink button-text rounded-xl px-6 py-3 w-full">Yeah,&nbsp;Delete It!</span>
                  </button>
                </div>
                <div className="block">
                  <button className="text-center w-full" onClick={() => setShowConfirmDelete(false)}>
                    <span className="w-full text-fryellow ">No. No! I've changed my mind!</span>
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <AddFeedDialog open={showAddFeed} setOpen={setShowAddFeed} />
      <FilterSavedItemsDialog open={showFilterSavedItems} setOpen={setShowFilterSavedItems} status={enabled ? 'archive' : 'new'} />
    </Fragment>
  );
}
