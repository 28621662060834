import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feed, FeedItem, SavedItem, Toast, User } from './index';
import { RootState } from 'app/rootReducer';
import { v4 as uuidv4 } from 'uuid';
import { FeedReaderApi } from 'service/ApiService';

export interface FeedState {
  feeds: Feed[];
  status: 'idle' | 'loading' | 'failed';
  authStatus: 'idle' | 'loading' | 'failed';
  toast: Toast | null;
  feedRequestState: FeedRequestState;
  user: User | undefined;
  isImport: boolean;
  savedItems: SavedItem[];
}

const initialState: FeedState = {
  feeds: [],
  status: 'idle',
  authStatus: 'idle',
  toast: null,
  feedRequestState: { rssUrl: undefined, status: 'idle' } as FeedRequestState,
  user: undefined,
  isImport: false,
  savedItems: []
};

export type FeedRequestParams = {
  rssUrl?: string;
  userId?: string;
  feedId?: string;
  feedRefId?: string;
  status?: string;
};

export type SavedItemsRequestParams = {
  userId: string;
  status: string;
  filter: string;
};

export type SavedItemsPostParams = {
  userId: string;
  url: string;
  title: string;
  feedTitle: string;
  imageSrc: string;
};

export type SavedItemsPutParams = {
  userId: string;
  url: string;
  status: string;
  tags: string;
};

export type LoginParams = {
  email: string;
  password: string;
};

export type UserParams = {
  authorization: any;
};

export type UserTestParams = {
  email: string;
};

type FeedRequestResponse = {
  data: Feed[];
  orginalRequestRssUrl: string;
};

type SavedItemsRequestResponse = {
  data: SavedItem[];
};

type UserRequestResponse = {
  data: User;
};

export type FeedRequestState = {
  rssUrl: string | undefined;
  status: 'idle' | 'loading' | 'failed' | 'success';
};

export type FeedItemRefIsDisplayedRequestParams = {
  feedItems: FeedItem[];
  userId: string;
};

interface APIError {
  details: string;
  message: string;
}

const standardErrorMessage = "Well, that's not great.";

export const sortFeedItemsByDate = (feedItems: FeedItem[]) => {
  feedItems.sort(function (x: FeedItem, y: FeedItem) {
    return Date.parse(y.pubDate) - Date.parse(x.pubDate);
  });
};

export const sortSavedItemsByDate = (savedItems: SavedItem[]) => {
  return [...savedItems].sort(function (x: SavedItem, y: SavedItem) {
    return parseInt(y.created) - parseInt(x.created);
  });
};

export const postUser = createAsyncThunk<UserRequestResponse, UserParams, { rejectValue: APIError }>('user', async (params, thunkApi) => {
  try {
    let response: UserRequestResponse = await FeedReaderApi.post('/user', params);
    //
    return response;
  } catch (error) {
    console.log(error);
    return thunkApi.rejectWithValue({ details: error, message: standardErrorMessage } as APIError);
  }
});

export const postTestUser = createAsyncThunk<UserRequestResponse, UserTestParams, { rejectValue: APIError }>('user/test', async (params, thunkApi) => {
  try {
    let response: UserRequestResponse = await FeedReaderApi.post('/user', params);
    //
    return response;
  } catch (error) {
    console.log(error);
    return thunkApi.rejectWithValue({ details: error, message: standardErrorMessage } as APIError);
  }
});

export const addFeedByUrl = createAsyncThunk<FeedRequestResponse, FeedRequestParams, { rejectValue: APIError }>('feed/post', async (params, thunkApi) => {
  try {
    await FeedReaderApi.post('/', { userId: params.userId, rssUrl: params.rssUrl, id: uuidv4() });

    let response: FeedRequestResponse = await FeedReaderApi.get(`/?userId=${params.userId}`);
    response.orginalRequestRssUrl = params.rssUrl!;
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue({ details: error, message: standardErrorMessage } as APIError);
  }
});

export const deleteFeed = createAsyncThunk<FeedRequestResponse, FeedRequestParams, { rejectValue: APIError }>('feed/delete', async (params, thunkApi) => {
  try {
    await FeedReaderApi.delete(`/?userId=${params.userId}&id=${params.feedRefId}`);

    let response: FeedRequestResponse = await FeedReaderApi.get(`/?userId=${params.userId}&status=unread`);

    return response;
  } catch (error) {
    return thunkApi.rejectWithValue({ details: error, message: standardErrorMessage } as APIError);
  }
});

export const refreshFeeds = createAsyncThunk<FeedRequestResponse, FeedRequestParams, { rejectValue: APIError }>('feeds/get', async (params, thunkApi) => {
  try {
    let status = params.status ?? 'unread';
    let response: FeedRequestResponse = await FeedReaderApi.get(`/?userId=${params.userId}&status=${status}`);

    return response;
  } catch (error) {
    return thunkApi.rejectWithValue({ details: error, message: standardErrorMessage } as APIError);
  }
});

export const requestMarkAllRead = createAsyncThunk<FeedRequestResponse, FeedRequestParams, { rejectValue: APIError }>('feeds/put', async (params, thunkApi) => {
  try {
    await FeedReaderApi.put(`/?feedRefId=${params.feedRefId}&userId=${params.userId}`);

    let response: FeedRequestResponse = await FeedReaderApi.get(`/?userId=${params.userId}&status=unread`);

    return response;
  } catch (error) {
    return thunkApi.rejectWithValue({ details: error, message: standardErrorMessage } as APIError);
  }
});

export const getUser = createAsyncThunk<UserRequestResponse, string, { rejectValue: APIError }>('user/get', async (email, thunkApi) => {
  try {
    let response: UserRequestResponse = await FeedReaderApi.get(`/user?email=${email}`);
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue({ details: error, message: standardErrorMessage } as APIError);
  }
});

export const putUser = createAsyncThunk<UserRequestResponse, User, { rejectValue: APIError }>('user/put', async (user, thunkApi) => {
  try {
    let response: UserRequestResponse = await FeedReaderApi.put(`/user`, user);
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue({ details: error, message: standardErrorMessage } as APIError);
  }
});

export const requestSavedItems = createAsyncThunk<SavedItemsRequestResponse, SavedItemsRequestParams, { rejectValue: APIError }>('savedItems/get', async (params, thunkApi) => {
  try {
    let status = params.status ?? 'new';
    let query = `/save/?userId=${params.userId}&status=${status}`;
    if (params.filter) {
      query = `${query}&filter=${params.filter}`;
    }
    let response: SavedItemsRequestResponse = await FeedReaderApi.get(query);

    return response;
  } catch (error) {
    return thunkApi.rejectWithValue({ details: error, message: standardErrorMessage } as APIError);
  }
});

export const postSavedItem = createAsyncThunk<SavedItemsRequestResponse, SavedItemsPostParams, { rejectValue: APIError }>('savedItems/post', async (params, thunkApi) => {
  try {
    let response: SavedItemsRequestResponse = await FeedReaderApi.post('/save', {
      userId: params.userId,
      url: params.url,
      title: params.title,
      feedTitle: params.feedTitle,
      imageSrc: params.imageSrc
    });

    return response;
  } catch (error) {
    return thunkApi.rejectWithValue({ details: error, message: standardErrorMessage } as APIError);
  }
});

export const putSavedItem = createAsyncThunk<SavedItemsRequestResponse, SavedItemsPutParams, { rejectValue: APIError }>('savedItems/put', async (params, thunkApi) => {
  try {
    let response: SavedItemsRequestResponse = await FeedReaderApi.put('/save', {
      userId: params.userId,
      url: params.url,
      tags: params.tags,
      status: params.status
    });

    return response;
  } catch (error) {
    return thunkApi.rejectWithValue({ details: error, message: standardErrorMessage } as APIError);
  }
});

const feedSlice = createSlice({
  name: 'feeds',
  initialState,
  reducers: {
    markAllRead: (state: FeedState, action: PayloadAction<string>) => {
      let feeds = state.feeds;
      if (action.payload !== 'all') {
        feeds = feeds.filter((feed) => feed.id !== action.payload);
      }
      for (const feed of feeds) {
        for (const feedItem of feed.items) {
          feedItem.status = 'read';
        }
      }
    },
    resetFeedRequestState: (state: FeedState) => {
      state.feedRequestState = { rssUrl: undefined, status: 'idle' } as FeedRequestState;
    },
    setIsImport: (state: FeedState, action: PayloadAction<boolean>) => {
      state.isImport = action.payload;
    },
    clearState: (state: FeedState) => {
      state.user = undefined;
      state.feeds = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addFeedByUrl.pending, (state, { meta, payload }) => {
      if (meta && meta.arg) {
        state.feedRequestState = { rssUrl: meta.arg.rssUrl, status: 'loading' };
      }
      state.status = 'loading';
      state.toast = null;
    });
    builder.addCase(addFeedByUrl.fulfilled, (state, { meta, payload }) => {
      if (payload) {
        state.feedRequestState = { rssUrl: payload.orginalRequestRssUrl, status: 'success' };
      }
      state.feeds = (payload.data as Feed[]) ?? [];
      state.status = 'idle';
      state.toast = null;
    });
    builder.addCase(addFeedByUrl.rejected, (state, { meta, payload }) => {
      if (meta && meta.arg) {
        state.feedRequestState = { rssUrl: meta.arg.rssUrl, status: 'failed' };
      }
      const error = payload as APIError;
      state.status = 'idle';
      state.toast = { message: error.message, details: error.details };
    });
    builder.addCase(refreshFeeds.pending, (state) => {
      state.status = 'loading';
      state.toast = null;
    });
    builder.addCase(refreshFeeds.fulfilled, (state, { meta, payload }) => {
      state.feeds = (payload.data as Feed[]) ?? [];
      state.status = 'idle';
      state.toast = null;
    });
    builder.addCase(refreshFeeds.rejected, (state, { meta, payload }) => {
      const error = payload as APIError;
      state.status = 'idle';
      state.toast = { message: error.message, details: error.details };
    });
    builder.addCase(deleteFeed.pending, (state) => {
      state.status = 'loading';
      state.toast = null;
    });
    builder.addCase(deleteFeed.fulfilled, (state, { meta, payload }) => {
      state.feeds = (payload.data as Feed[]) ?? [];
      state.status = 'idle';
      state.toast = null;
    });
    builder.addCase(deleteFeed.rejected, (state, { meta, payload }) => {
      const error = payload as APIError;
      state.status = 'idle';
      state.toast = { message: error.message, details: error.details };
    });
    builder.addCase(requestMarkAllRead.pending, (state) => {
      state.status = 'loading';
      state.toast = null;
    });
    builder.addCase(requestMarkAllRead.fulfilled, (state, { meta, payload }) => {
      state.feeds = (payload.data as Feed[]) ?? [];
      state.status = 'idle';
      state.toast = null;
    });
    builder.addCase(requestMarkAllRead.rejected, (state, { meta, payload }) => {
      const error = payload as APIError;
      state.status = 'idle';
      state.toast = { message: error.message, details: error.details };
    });
    builder.addCase(postUser.pending, (state) => {
      state.authStatus = 'loading';
      state.toast = null;
    });
    builder.addCase(postUser.fulfilled, (state, { meta, payload }) => {
      state.user = payload.data;
      state.authStatus = 'idle';
      state.toast = null;
    });
    builder.addCase(postUser.rejected, (state, { meta, payload }) => {
      //const error = payload as APIError;
      state.authStatus = 'failed';
      //state.toast = { message: error.message, details: error.details };
      state.toast = { message: 'Unable To Sign In', details: 'There was an error with sign in.' };
    });
    builder.addCase(postTestUser.pending, (state) => {
      state.authStatus = 'loading';
      state.toast = null;
    });
    builder.addCase(postTestUser.fulfilled, (state, { meta, payload }) => {
      state.user = payload.data;
      state.authStatus = 'idle';
      state.toast = null;
    });
    builder.addCase(postTestUser.rejected, (state, { meta, payload }) => {
      //const error = payload as APIError;
      state.authStatus = 'failed';
      //state.toast = { message: error.message, details: error.details };
      state.toast = { message: 'Unable To Sign In', details: 'There was an error with sign in.' };
    });
    builder.addCase(getUser.pending, (state) => {
      state.toast = null;
    });
    builder.addCase(getUser.fulfilled, (state, { meta, payload }) => {
      state.user = payload.data;
      state.toast = null;
    });
    builder.addCase(getUser.rejected, (state, { meta, payload }) => {
      const error = payload as APIError;
      state.toast = { message: error.message, details: error.details };
    });
    builder.addCase(putUser.pending, (state) => {
      state.toast = null;
    });
    builder.addCase(putUser.fulfilled, (state, { meta, payload }) => {
      state.user = payload.data;
      state.toast = null;
    });
    builder.addCase(putUser.rejected, (state, { meta, payload }) => {
      const error = payload as APIError;
      state.toast = { message: error.message, details: error.details };
    });
    builder.addCase(requestSavedItems.pending, (state) => {
      state.status = 'loading';
      state.toast = null;
    });
    builder.addCase(requestSavedItems.fulfilled, (state, { meta, payload }) => {
      state.savedItems = (payload.data as SavedItem[]) ?? [];
      state.status = 'idle';
      state.toast = null;
    });
    builder.addCase(requestSavedItems.rejected, (state, { meta, payload }) => {
      const error = payload as APIError;
      state.status = 'idle';
      state.toast = { message: error.message, details: error.details };
    });
    builder.addCase(postSavedItem.pending, (state) => {
      state.status = 'loading';
      state.toast = null;
    });
    builder.addCase(postSavedItem.fulfilled, (state, { meta, payload }) => {
      state.savedItems = (payload.data as SavedItem[]) ?? [];
      state.status = 'idle';
      state.toast = null;
    });
    builder.addCase(postSavedItem.rejected, (state, { meta, payload }) => {
      const error = payload as APIError;
      state.status = 'idle';
      state.toast = { message: error.message, details: error.details };
    });
    builder.addCase(putSavedItem.pending, (state) => {
      state.status = 'loading';
      state.toast = null;
    });
    builder.addCase(putSavedItem.fulfilled, (state, { meta, payload }) => {
      state.savedItems = (payload.data as SavedItem[]) ?? [];
      state.status = 'idle';
      state.toast = null;
    });
    builder.addCase(putSavedItem.rejected, (state, { meta, payload }) => {
      const error = payload as APIError;
      state.status = 'idle';
      state.toast = { message: error.message, details: error.details };
    });
  }
});

export const getFeeds = (state: RootState) => {
  return state.feeds;
};

export const getSavedItems = (state: RootState) => {
  return state.feeds.savedItems;
};

export const getAllFeedItems = (state: RootState) => {
  const feeds = state.feeds.feeds;
  let allFeedItems: FeedItem[] = [];
  for (const feed of feeds) {
    if (feed.items) {
      for (const feedItem of feed.items) {
        allFeedItems.push(feedItem);
      }
    }
  }
  sortFeedItemsByDate(allFeedItems);
  return allFeedItems;
};

export const getAllSavedItems = (state: RootState) => {
  return sortSavedItemsByDate(state.feeds.savedItems);
};

export const getSignedInUser = (state: RootState) => {
  return state.feeds.user;
};

export const getHighlightedWords = (state: RootState) => {
  return state.feeds.user?.highlightedWords;
};

export const getReplacementWords = (state: RootState) => {
  return state.feeds.user?.replacementWords;
};

export const getIsImport = (state: RootState) => {
  return state.feeds.isImport;
};

export const { markAllRead, resetFeedRequestState, setIsImport, clearState } = feedSlice.actions;

export default feedSlice.reducer;
