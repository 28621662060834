import devices from 'assets/devices.png';
import mobileCropped from 'assets/mobile_cropped.png';
import Footer from './Footer';
import logo192 from 'assets/logo192.png';
import Pricing from './Pricing';
import Features from './Features';
import SignInWithApple from './SignInWithApple';

export default function Home() {
  return (
    <div>
      <div className="bg-gradient-to-tl to-slate-900 via-slate-800 from-frpurple-700">
        <div className="relative overflow-hidden">
          <div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
            <div className="relative h-full"></div>
          </div>

          <div className="relative py-12 sm:py-24">
            <div className="mx-auto max-w-7xl">
              <div className="md:grid md:grid-cols-12">
                <div className="md:col-span-6 flex">
                  <div className="px-6 text-center lg:text-left lg:flex lg:items-center mx-auto">
                    <div>
                      <div className="md:flex md:justify-start">
                        <img className="mx-auto rounded-xl overflow-hidden self-center w-20 md:mr-6 mb-6 md:mb-0" src={logo192} alt="letsreadsomestuuff.com logo" />
                        <div>
                          <div className="text-fryellow block cartridge-reg text-4xl lg:text-5xl xl:text-6xl mx-auto text-center md:text-left">Lukemisto</div>
                          <div className="block tracking-tight font-bold text-white text-2xl mx-auto text-center md:text-left">It's a simple RSS Reader</div>
                        </div>
                      </div>
                      <div className="mt-3 sm:mt-5 ">
                        <p className=" text-gray-300 text-xl text-center sm:text-left">
                          <span className="font-bold text-frpink">Subscribe, Read and Mark As Read.</span>
                          <div>Sometimes the basics are kind of great.</div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-16 sm:mt-24 md:mt-0 md:col-span-6 sm:px-6 w-full">
                  <SignInWithApple />
                </div>
              </div>
            </div>
          </div>

          <div className="relative">
            <div className="absolute inset-0 flex flex-col" aria-hidden="true">
              <div className="flex-1" />
              <div className="flex-1 w-full bg-gray-900" />
            </div>
            <div className="hidden sm:block max-w-7xl mx-auto px-6">
              <img className="relative shadow-xl" src={devices} alt="Desktop screenshot" />
            </div>
            <div className="block sm:hidden max-w-7xl mx-auto px-6">
              <img className="relative shadow-xl" src={mobileCropped} alt="Mobile Screenshot" />
            </div>
          </div>
        </div>
        <div className="bg-gray-900">
          <div className="max-w-7xl mx-auto pb-0 sm:pb-24"></div>
        </div>

        <div>
          <Features />
        </div>
        <div>
          <Pricing />
        </div>
      </div>

      <Footer />
    </div>
  );
}
