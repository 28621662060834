import { Dialog, Transition } from '@headlessui/react';
import { useAppDispatch } from 'app/hooks';
import { addFeedByUrl, getSignedInUser } from 'model/feedSlice';
import prependHttp from 'prepend-http';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  open: boolean;
  setOpen: Function;
};

export default function AddFeedDialog(props: Props) {
  const dispatch = useAppDispatch();

  const user = useSelector(getSignedInUser);
  const [newUrl, setNewUrl] = useState('');

  const handleAddUrl = () => {
    if (newUrl.length > 0 && user?.email) {
      dispatch(addFeedByUrl({ rssUrl: prependHttp(newUrl), userId: user.email }));
      props.setOpen(false);
      setNewUrl('');
    }
  };
  return (
    <Fragment>
      {/*start add feed modal*/}
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => props.setOpen(false)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-gray-900 rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm w-full sm:p-6">
                <div className="block mb-12">
                  <div className="mb-1.5">
                    <label className="label-text">RSS Feed URL</label>
                  </div>
                  <input
                    type="text"
                    name="feed-url"
                    id="feed-url"
                    className="input-text input-background input-outline rounded-xl overflow-hidden input-border"
                    placeholder="www.example.com/rss"
                    onChange={(event) => {
                      setNewUrl(event.target.value);
                    }}
                  />
                </div>
                <div className="block">
                  <button onClick={handleAddUrl} className="pushable bg-frpink-700 rounded-xl w-full">
                    <span className="front bg-frpink button-text rounded-xl px-6 py-3 w-full">Add&nbsp;Feed</span>
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}
