import { Nav } from 'components';
import { getFeeds, getIsImport } from 'model/feedSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddToHomeScreen from './AddToHomeScreen';
import LoadingOverlay from './LoadingOverlay';
import Toasts from './Toasts';
import WelcomeOverlay from './WelcomeOverlay';

type Props = {
  content: JSX.Element;
};

export default function ContentContainer(props: Props) {
  const { content } = props;

  const feedState = useSelector(getFeeds);
  const isImport = useSelector(getIsImport);
  let hasShownWelcome = localStorage.getItem('hasShownWelcome');
  const [showWelcome, setShowWelcome] = useState(hasShownWelcome !== 'true');

  useEffect(() => {
    if (hasShownWelcome !== 'true') {
      setShowWelcome(true);
      localStorage.setItem('hasShownWelcome', 'true');
    }
  }, [hasShownWelcome, setShowWelcome]);
  return (
    <div className="h-full bg-gray-800">
      {feedState.status === 'loading' && !isImport && <LoadingOverlay />}
      {showWelcome && <WelcomeOverlay />}
      <Nav />
      {content}
      <Toasts />
      {/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent) && <AddToHomeScreen />}
    </div>
  );
}
