import React, { useEffect } from 'react';
import './App.css';
import { FeedList, ContentContainer, Home, PrivacyPolicy } from 'components';
import { Switch, BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import FeedDetail from 'components/FeedDetail';
import { FeedRequestParams, getSignedInUser, getUser, refreshFeeds, requestSavedItems, SavedItemsRequestParams } from 'model/feedSlice';
import AllItems from 'components/AllItems';
import { useAppDispatch } from 'app/hooks';
import Settings from 'components/Settings';
import { useSelector } from 'react-redux';
import SavedItems from 'components/SavedItems';
import ExtensionSignin from 'components/ExtensionSignin';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCW8DYwuXDIiiPQ2_ktFdp7wECDXnBb5-M',
  authDomain: 'lukemisto.firebaseapp.com',
  projectId: 'lukemisto',
  storageBucket: 'lukemisto.appspot.com',
  messagingSenderId: '900623903775',
  appId: '1:900623903775:web:099f682fe40ef2ea7e392a',
  measurementId: 'G-K20TK90J91'
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  logEvent(analytics, 'first_open', undefined);

  const user = useSelector(getSignedInUser);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (user && user.email) {
      dispatch(getUser(user.email));
    }
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(refreshFeeds({ userId: user.email } as FeedRequestParams));
      dispatch(requestSavedItems({ userId: user.email } as SavedItemsRequestParams));
    }
  }, [user, dispatch]);

  if (user) {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/feed/:feedId">
              <ContentContainer content={<FeedDetail />} />
            </Route>
            <Route path="/feeds">
              <ContentContainer content={<FeedList />} />
            </Route>
            <Route path="/all">
              <ContentContainer content={<AllItems />} />
            </Route>
            <Route path="/saved">
              <ContentContainer content={<SavedItems />} />
            </Route>
            <Route path="/settings">
              <ContentContainer content={<Settings />} />
            </Route>
            <Route path="/">
              <Redirect to="/all" />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  } else {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/extension-signin">
              <ExtensionSignin />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
